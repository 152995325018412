
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import { ContentLoader } from 'vue-content-loader';
import { Modal } from "bootstrap";
import moment from "moment";
import { getBaseAgendamento, getBaseAgendamentoEntrega } from '@/services/AgendaService';
import useEmitter from '@/composables/Emmiter';
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import useUtilChanges from '@/composables/UtilChanges';

export default defineComponent({
  name: "agenda-virtual-recepcao-camp",
  components: {
    ContentLoader,
    ElConfigProvider
  },

  setup() {
    const store = useStore();
    const emitter = useEmitter();

    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const concessionariaInfo:any = ref({});

    const refCalendar:any = ref(null);
    const loading:any = ref(false);
    const resources:any = ref([]);
    const consultores:any = ref([]);
    const eventInfo:any = ref();
    let events:any = ref([]);
    let refRegexMarca:any = ref("");
    const isUserActive = ref<any>(false);
    const agendamentosEntreguesConflitantes:any = ref([]);
    const cacheVisibleTime = window.localStorage.getItem("agendamentoVisibleType");
    const visibleTime:any = ref(cacheVisibleTime ?? "1");
    const { replacePlaca } = useUtilChanges();

    const cacheAgendamentosEntregues:any = ref([]);
    const cacheAgendamentos:any = ref([]);
    const dataMesAtual = moment().startOf("day").toISOString();
    const dateCalendarFilter = ref(dataMesAtual);
    const timeNow = ref(moment().format("HH:mm:ss"));
    const dateNow = ref(moment().locale("pt-br").format("LL"));

    const statusOS:any =  reactive({
      data: {
        aguardaServico: [],
        emServico: [],
        aguardaInspecao: [],
        aguardaEntrega: [],
      },
      dataFirstPage: [],
      dataLastPage: [],
      current_page: 1,
      total: 0,
      per_page: 20, //In two tables
    });

    const refStatus = [
      {
        nome: "Agendado",
        cor: "#F2B208"
      },
      {
        nome: "Atrasado",
        cor: "#d20000"
      },
      {
        nome: "Recebido",
        cor: "#008000"
      },
    ];
    
    const refIntervals = reactive({
      baseAgendamento: 0,
      timeNow: 0
    });

    const lastUpdatedAgendamentos:any = ref(null);

    // LIFECYCLE HOOKS
    onMounted(async () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;

      if(store.getters.consultoresList.length > 0){
        consultores.value = store.getters.consultoresList;
        trataConsultores();
      };

      startPage();

      // EMITTER HOOKS
      emitter.on("update-agenda-recepcao", () => {
        startPage();
      });

      emitter.on("agenda-clear-interval", () => {
        clearAllSetInterval();
      });

      refIntervals.timeNow = setInterval(() => {
        timeNow.value = moment().format("HH:mm:ss");        
      }, 1000);

      // TODO Tranformar em socket 
      inactivityTime();
    });

    onBeforeUnmount(() => {
      if(refIntervals.timeNow){
        clearInterval(refIntervals.timeNow);
      }
      clearAllSetInterval();

      emitter.off("update-agenda-recepcao");
      emitter.off("agenda-clear-interval");
    });

    // WATCH HOOKS
    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.consultoresList, () => {
      consultores.value = store.getters.consultoresList;

      trataConsultores();
    }, { deep: true });


    store.watch(() => store.getters.baseAgendamentosList, () => {
      clearAllSetInterval();
      loading.value = false;

      refIntervals.baseAgendamento = setInterval(() => {
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
      }, 300000);

      separaAgendamentos(store.getters.baseAgendamentosList, 1);
    }, { deep: true });

    // FUNCTIONS
    function startPage(){
      window.dispatchEvent(new Event('resize'));
      inactivityTime();

      clearAllSetInterval();
      const auxUpdateTimeAgendamento = store.getters.refLastUpdatedTime.lastBaseAgendamentos;

      if(!auxUpdateTimeAgendamento || moment().isAfter(auxUpdateTimeAgendamento.clone().add(210, "seconds"))){
        loading.value = true;

        store.dispatch(Actions.SET_BASE_AGENDAMENTOS).then(() => {
          loading.value = false;
        });
      } else {
        separaAgendamentos(store.getters.baseAgendamentosList, 1);
      }

      refIntervals.baseAgendamento = setInterval(() => {
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
      }, 300000);
    };

    function separaAgendamentos(agendamentos, page = 1) {
      timeNow.value = `${moment().format("DD/MM/YY")}, ${moment().format("HH:mm")}`;        

      let auxStatus:any = agendamentos.filter(agendamento => agendamento.osaberta).map(agendamento => {
        const { servicosAgendados } : any = agendamento;

        const servicoAuxiliarMaiorStatus = servicosAgendados.length > 0 ? servicosAgendados.reduce((agendamentoA, agendamentoB) => {
          return agendamentoA.status >= agendamentoB.status ? agendamentoA : agendamentoB
        }) : [];

        const servicoAuxiliarMenorStatus = servicosAgendados.length > 0 ? servicosAgendados.reduce((agendamentoA, agendamentoB) => {
          return agendamentoA.status < agendamentoB.status ? agendamentoA : agendamentoB
        }) : [];

        let auxStatus = "";
        let auxProgresso = "";

        if(agendamento.servicosAgendados.length == 0 || servicoAuxiliarMaiorStatus.status == 1){
          auxStatus = "Aguardando Serviço";
          auxProgresso = "1";
        } else if(servicoAuxiliarMaiorStatus.status == 4 || servicoAuxiliarMaiorStatus.status == 5 || servicoAuxiliarMenorStatus.status == 4 || servicoAuxiliarMenorStatus.status == 5){
          return;
        } else if(servicoAuxiliarMaiorStatus.status == 2 || (servicoAuxiliarMenorStatus.status == 1 || servicoAuxiliarMenorStatus.status == 2)) {
          auxStatus = "Em Serviço";
          auxProgresso = "2";
        } else if(servicoAuxiliarMenorStatus.status == 3){
          auxStatus = "Aguardando Inspeção";
          auxProgresso = "3";
        } else if(servicoAuxiliarMenorStatus.status == 6){
          auxStatus = "Aguardando Entrega";
          auxProgresso = "4";
        } else {
          return;
        }

        const auxOsInfo = {
          ...agendamento,
          statusOS: auxStatus,
          progressoOS: auxProgresso,
          consultorTratado: trataNome(agendamento.consultor_agendado)
        };

        return auxOsInfo;
      }).filter(statusOSTratado => statusOSTratado);

      auxStatus.sort((osTratataA, osTratadaB) => (osTratataA.progressoOS > osTratadaB.progressoOS) ? 1 : -1);

      statusOS.data.aguardaServico = auxStatus.filter(osTratada => osTratada.progressoOS == "1");
      statusOS.data.emServico = auxStatus.filter(osTratada => osTratada.progressoOS == "2");
      statusOS.data.aguardaInspecao = auxStatus.filter(osTratada => osTratada.progressoOS == "3");
      statusOS.data.aguardaEntrega = auxStatus.filter(osTratada => osTratada.progressoOS == "4");

      statusOS.total = auxStatus.length;      
      statusOS.current_page = page;
      // 1
      // 0
      // 20

      // 2
      // 20
      // 40

      // 3
      // 40
      // 60


      const auxStart = statusOS.per_page * (page - 1);
      const auxEnd = statusOS.per_page * page;
      const allData = auxStatus.slice(auxStart, auxEnd);

      statusOS.dataFirstPage = allData.slice(0, 10);
      statusOS.dataLastPage = allData.slice(10, 20);
    };


    async function buscaTodosAgendamentos(){
      const diferencaDias:any = moment(refCalendar.value.getApi().getDate()).startOf('day').diff(moment().startOf('day'), "days", true).toFixed(0) ?? 0;

      let responseAgendamentos = [];
      if(diferencaDias == 0){
        responseAgendamentos = store.getters.baseAgendamentosList;
      } else {
        loading.value = true;
        responseAgendamentos = await getBaseAgendamento(concessionariaInfo.value.codConcessionaria, diferencaDias).then(data => data).catch((e) => {
          return [];
        });
        loading.value = false;
      }
    };

    function clearAllSetInterval(){
      clearInterval(refIntervals.baseAgendamento);
    }

    function inactivityTime () {
      let time;
      // reset timer
      window.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;
      function doSomething() {
        isUserActive.value = false;
      }
      function resetTimer() {
        isUserActive.value = true;
        clearTimeout(time);
        time = setTimeout(doSomething, 5000)
      }
    };

    function changeCalendarDate(date){
      if(date){
        // refCalendar.value.getApi().gotoDate(date);
        buscaTodosAgendamentos();
      }
    };

    async function trataConsultores(){
      while(resources.value.length) {
        resources.value.pop();
      }

      consultores.value.forEach((consultor, index) => {
        const resource = {
          id: Number(consultor.codConsultor),
          title: consultor.nome,
          eventColor: "none",
          eventBackgroundColor: "none",
          eventBorderColor: "none",
          extendedProps: {
            nome: consultor.nome,
            foto: consultor.imagem,
            color: consultor.corAgenda,
            servico: "",
            num: index + 1,
            status: "Plano"
          }
        };

        resources.value.push(resource);
      });
    };

    function getCodConsultor(nomeConsultor) {
      if(!nomeConsultor){
        return 0
      }

      let codConsultor = consultores.value.find(consultor => {
        return removeAcentos(consultor.intConsultor, true) == removeAcentos(nomeConsultor, true)
      })?.codConsultor;

      if(!codConsultor){
        codConsultor = consultores.value.find(consultor => {
          return removeAcentos(consultor.nome, true) == removeAcentos(nomeConsultor, true)
        })?.codConsultor
      }

      return codConsultor ?? 0;
    }

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    async function trataAgendamentos(response){
      let regexMarca:any;

      response.forEach(agendamento => {
        const dataAgendamento = moment(`${agendamento.dataagendamento} ${agendamento.horaagendada}`, "DD/MM/YYYY HH:mm");
        
        let status = 0;
        if(agendamento.osaberta) {
          status = 2;
        } else if(dataAgendamento.clone().add(40, "minutes").diff(moment(), "minutes") < 0){
          status = 1;
        }

        regexMarca = agendamento.marca ? new RegExp('('+agendamento.marca+')', 'gi') : "";

        let title = "";
        if(status == 0){
          title = `Agendamento - ${dataAgendamento.clone().format("HH:mm")}`;
        } else if(status == 1){
          title = `Atrasado - ${dataAgendamento.clone().format("HH:mm")}`;
        } else if(status == 2){
          title = `Recebido - OS ${agendamento.osaberta}`;
        }

        const newEvent = {
          id: agendamento.idagendamento,
          resourceId: getCodConsultor(agendamento.consultorcodigo ?? agendamento.consultor_agendado),
          start: dataAgendamento.format(),
          end: dataAgendamento.clone().add(38, "minutes").format(),
          extendedProps: {
            inicio: dataAgendamento.clone().format("HH:mm"),
            fim: dataAgendamento.clone().add(40, "minutes").format("HH:mm"),
            ...agendamento,
            tipoCod: 1,
            statusName: refStatus[status].nome,
            statusColor: refStatus[status].cor,
            status,
            title,
            tipo: "Agendamento",
            cliente: trataNome(agendamento.cliente),
            consultor: trataNome(agendamento.consultor_agendado),
            modelo: agendamento.modelo ? agendamento.modelo.replace(regexMarca, '') : "",
          }
        };

        // refCalendar.value.getApi().addEvent(newEvent);
      });

      refRegexMarca.value = regexMarca;
    };

    async function trataAgendamentosEntregues(response){
      response.forEach(agendamentoEntrega => {
        const dataAgendamentoEntrega = moment(agendamentoEntrega.osdataprometidaentrega, "YYYY-MM-DD HH:mm:ss");

        const title = `Entrega - ${dataAgendamentoEntrega.clone().format("HH:mm")} - OS ${agendamentoEntrega.osnumero ?? ''}`;

        const newEvent = {
          id: `${agendamentoEntrega.idagendamento}_entrega`,
          resourceId: getCodConsultor(agendamentoEntrega.consultorcodigo ?? agendamentoEntrega.consultornome),
          start: dataAgendamentoEntrega.format(),
          end: dataAgendamentoEntrega.clone().add(19, "minutes").format(),
          extendedProps: {
            inicio: dataAgendamentoEntrega.clone().format("HH:mm"),
            fim: dataAgendamentoEntrega.clone().add(20, "minutes").format("HH:mm"),
            ...agendamentoEntrega,
            statusName: "",
            statusColor: "#173872",
            status: "",
            tipoCod: 2,
            tipo: "Entrega Planejada",
            title,
            agendamentoNoMesmoHorario: false,
            cliente: trataNome(agendamentoEntrega.cliente ?? ""),
            consultor: trataNome(agendamentoEntrega.consultornome),
            modelo: agendamentoEntrega.modelo ? agendamentoEntrega.modelo.replace(refRegexMarca.value, '') : "",
            passante: !agendamentoEntrega.idagendamento,
          }
        };
        
        // refCalendar.value.getApi().addEvent(newEvent);
      });
    };

    function calendarFullscreen() {
      if(document.fullscreenElement){
        document.exitFullscreen();
        return
      } 
      let elem:any = document.querySelector("#card-calendar-camp-recepcao");
      if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "show" });
      } else if (elem.mozRequestFullScreen) { /* Firefox */
          elem.mozRequestFullScreen({ navigationUI: "show" });
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen({ navigationUI: "show" });
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
          elem.msRequestFullscreen({ navigationUI: "show" });
      }
    };

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]}` 

      return nomeTratado;
    }

    return {
      ptBr,
      events,
      refCalendar,
      resources,
      eventInfo,
      calendarFullscreen,
      loading,
      concessionariaInfo,
      codNivel,
      consultores,
      timeNow,
      dateNow,
      isUserActive,
      refStatus,
      agendamentosEntreguesConflitantes,
      visibleTime,
      headerTitle: computed(() => store.getters.concessionariaSelectedInfo.nomeFantasia),
      dateCalendarFilter,
      changeCalendarDate,
      ...toRefs(statusOS),
      replacePlaca
    };
  },
});
